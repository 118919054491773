export default {
  messages: {
    uk: {
      'Долучайтесь до ініціативи Біле світло': 'Долучайтесь до ініціативи Біле світло',
      'Будуйте перевірений авторинок': 'Будуйте перевірений авторинок разом з AUTO.RIA',
      'Переглянути деталі': 'Переглянути деталі',
    },
    ru: {
      'Долучайтесь до ініціативи Біле світло': 'Присоединяйтесь к инициативе Біле світло',
      'Будуйте перевірений авторинок': 'Стройте проверенный авторынок вместе с AUTO.RIA',
      'Переглянути деталі': 'Просмотреть детали',
    },
  },
};
