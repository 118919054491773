import { mapGetters } from 'vuex';
import SearchHistory from './SearchHistory/index.vue';
import Notepad from './Notepad/index.vue';
import Notifications from './Notifications/index.vue';
import Subscriptions from './Subscriptions/index.vue';
import ForBusiness from './ForBusiness/index.vue';
import ToGatherIdeas from './ToGatherIdeas/index.vue';
import CommercialServices from './CommercialServices/index.vue';
import PublicationDiscounts from './PublicationDiscounts/index.vue';
import UnpaidOrders from './UnpaidOrders/index.vue';
import PersonalBill from './PersonalBill/index.vue';
import Statistics from './Statistics/index.vue';
import InvestmentsAndProfit from './InvestmentsAndProfit/index.vue';
import ManageCalls from './ManageCalls/index.vue';
import OrdersCustomers from './OrdersCustomers/index.vue';
import PackageTOP from './PackageTOP/index.vue';
import Evaluation from '../components/Evaluation/index.vue';
import AboutAuto from './AboutAuto/index.vue';
import Alerts from '../components/Alerts/index.vue';
import Promo from './Promo/index.vue';
import PromoRotator from './PromoRotator/index.vue';
import Advertisements from './Advertisements/index.vue';
import PostModeration from './PostModeration/index.vue';
import Rotator from './Rotator/index.vue';
import AdvertisementsMenu from './AdvertisementsMenu/index.vue';
import TopShare from './TopShare/index.vue';
import PersonalManager from '../components/LeftBar/PersonalManager/index.vue';
import { get } from '../../../helpers/cookie';
import WhiteLight from "./WhiteLight/index.vue";

export default {
  init({ store, context }) {
    let cookies = {};
    if (context) {
      cookies = context.request.headers.cookie;
    }

    return store.dispatch('cabinet/blocks/blocks', cookies);
  },

  components: {
    'search-history-block': SearchHistory,
    'notepad-block': Notepad,
    'notifications-block': Notifications,
    'subscriptions-block': Subscriptions,
    'for-business-block': ForBusiness,
    'to-gather-ideas': ToGatherIdeas,
    'white-light': WhiteLight,
    'about-auto': AboutAuto,
    'commercial-services': CommercialServices,
    'publication-discounts': PublicationDiscounts,
    'unpaid-orders': UnpaidOrders,
    'personal-bill': PersonalBill,
    statistics: Statistics,
    'investments-and-profit': InvestmentsAndProfit,
    'manage-calls': ManageCalls,
    'orders-customers': OrdersCustomers,
    'package-top': PackageTOP,
    evaluation: Evaluation,
    alerts: Alerts,
    promo: Promo,
    'promo-rotator': PromoRotator,
    'post-moderation': PostModeration,
    'advertisements-preview': Advertisements,
    'cabinet-rotator': Rotator,
    'advertisements-menu': AdvertisementsMenu,
    'top-share': TopShare,
    'personal-manager': PersonalManager,
  },
  data() {
    return {
      showRotator: false,
    };
  },
  computed: {
    ...mapGetters({
      blocks: 'cabinet/blocks/blocks',
      langPrefix: 'lang/prefix',
    }),
  },
  mounted() {
    // TODO https://auto-ria.atlassian.net/browse/VCWEB-4310
    // ніби пофіксилось, якщо слайдер грузиться пізніше то сторінка не лагає,
    // потрібно пізніше прибрати гет параметр і просто поставити умову загрузки слайдера
    // після того як сторінка відрендериться
    this.showRotator = !this.$route.query.test_slow_lk;
  },
};
